@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400&family=Sawarabi+Mincho&display=swap");
html {
  font-size: 16px;
  scroll-behavior:smooth;
}
body {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.5rem;
}
footer{
  position: fixed;
  width:100%;
  bottom:20px;
  right:10px;
  text-align:right;
  z-index: 1000;
}
input, textarea {
  font-family: "Sawarabi Mincho", sans-serif;
}
/* input と textarea のスタイルを合わせる */
textarea, input {
  resize: none;
  border-width: 1px;
  border-style: solid;
}
/* width 100%にすると若干はみ出してしまう */
textarea {
  box-sizing: border-box;
}
button {
  font-size: 1.5rem;
  padding-left: 40px;
  padding-right: 40px;
  border-style: hidden;
  border-radius: 10px;
}
input {
  font-size: 1.5rem;
}
/* ブラウザによっては以下の要素はスタイルを継承しない
 * ので、継承するように設定を行なっている
 */
button, select {
  font-family : inherit;
}
button, input, select, textarea {
  font-size : 100%;
}
.submitButton {
  font-weight: lighter;
  background-color: #008080;
  color: #FFFFDD;
}
.submitButton[disabled] {
  font-weight: lighter;
  background-color: darkgray;
  color: black;
}
.normalButton {
  background-color: black;
  color: white;
}
.normalButton[disabled] {
  font-weight: lighter;
  background-color: darkgray;
  color: black;
}
.smallButton {
  background-color: black;
  color: white;
  font-size: 1.1rem;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: 5px;
  display: block;
  padding-right: 10px;
  padding-left: 10px;
}
.fontLarge {
  font-size: 2rem;
}
.fontMedium {
  font-size: 1.5rem;
}
.fontSmall {
  font-size: 1rem;
}
